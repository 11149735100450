import React, { useState } from 'react';
import './styles/classification.css';
import Banner_image from '../assets/svgs/Banner_image.png';
import ClassificationCard from '../Cards/ClasificationCard';
// import Data from './data/classification.json';
import ArrowBottom from '../assets/svgs/arrow.png';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import ResultTable from './utils/resultTable';
import LossChart from './utils/lossChart';
import logo from '../assets/svgs/logo.svg'; 


const schema = yup.object({
  prompt: yup.string().required('Prompt is required').max(3200, 'Max length exceeded')
}).required();

const Newlanding = ({ Data }) => {

  const [err, setErr]= useState(''); 
  const [evaluation, setEvaluation]= useState(0) ; 
  const [completion, setCompletion]= useState('');
  const [moreData, setMoreData] = useState(5);
  const [steps, setSteps]= useState(Data[0]?.evaluation?.steps);

  const { register, handleSubmit, formState: { errors } , setValue } = useForm({
    resolver: yupResolver(schema)
  }); 

  const onSubmit = () => {}

  return (
    <>
    <div className='new_landing'>
        <div className="container">
          <div className="banner">
            <div className="left_section ">
              <h1 className="title mb-4">{Data[0].friendly_name}</h1>
              <p className='desription'>
                {/* {
                  `The OpenAI  base model ${Data[0].finetune.base_model} fine-tuned 
                  specifically for the ${Data[0].friendly_name} task 
                  using  dataset of ${Data[0].data.totalCount} prompt & completion pairs.
                  The fine-tuning process lasted for ${Data[0].finetune.hyperparams.n_epochs} epochs
                  with a batch size of ${Data[0].finetune.hyperparams.batch_size}.
                  This fine-tuning cost $${Data[0].evaluation.cost}.`
                } */}
                {Data[0].main}
              </p>  
            </div>
            <div className="right_section">
              <div className='banner_img text-center'>
                <img width={'50%'} src={Data[0].img} alt="Banner_image" />
              </div>
            </div>
          </div>
          <div className='card_section'>
            <div>
              {
                Data.map((item, i) => {
                  return <ClassificationCard key={i} cardData={item.data} moreData={moreData} />
                })
              }
                <div className="more_opstion text-center">
                  <a href={Data[0].data_url} target="_blank" rel="noreferrer">
                    <span className='more'>+{Data[0].data.totalCount- 6} more rows</span>
                  </a>
                </div> 
            </div>
            <div className="data_section">
              <div className="title">Data</div>
              <div className="description">
                {Data[0].data_description}
              </div>
              <a href={Data[0].data_url} target="_blank" rel="noreferrer">
                <button className='download_btn'><img src={ArrowBottom} alt="ArrowBottom" /> Download </button>
              </a>
            </div>
          </div>
          
          <div className='detection_task'>
            <h2 className='title mb-3'>{Data[0].finetune.base_model.charAt(0).toUpperCase() + Data[0].finetune.base_model.slice(1)} Fine-tuned for {Data[0].friendly_name} Task</h2>
            <p className='description mb-2'> The hyperparameters used for training were - </p>
            
            <ul className='task_data'>
              <li> batch_size = {Data[0].finetune.hyperparams.batch_size}</li>
              <li>learning_multiplier = {Data[0].finetune.hyperparams.learning_multiplier ? Data[0].finetune.hyperparams.learning_multiplier : 0}</li>
              <li>n_epochs = {Data[0].finetune.hyperparams.n_epochs}</li>
              <li>prompt_loss_weight = {Data[0].finetune.hyperparams.prompt_loss_weight}</li>
            </ul>

            <div className="fineTunedModel">

              <div className="container p-0">
                <div className="tabs">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">             
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="graph-tab" data-toggle="tab" href="#graph" role="tab" aria-controls="graph" aria-selected="true">Graph</a>
                    </li>

                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="table-tab" data-toggle="tab" href="#table" role="tab" aria-controls="table" aria-selected="false">Table</a>
                    </li>

                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    
                    <div class="tab-pane fade show active" id="graph" role="tabpanel" aria-labelledby="graph-tab">
                      <LossChart data={steps}/>
                    </div>

                    <div class="tab-pane fade" id="table" role="tabpanel" aria-labelledby="table-tab">
                      <ResultTable data={Data[0].evaluation.steps} />
                    </div>

                  </div>
                </div>

                <hr />
                <div className="fine-content inference">
                  {/* <div className="title">⚡️ Hosted inference API <InfoIcon /></div> */}
                  <div className="Classification-computation-content">
                    <div className="Classification-computation-time">
                      <div className="Classification">
                        <div className="title"> Fine-tuned Model Inference &#x1F389;</div>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                        <div>
                          {/* <input type="text"  {...register("prompt", { required: true })}  placeholder={card && card.prompt } className="increasing" /> */}
                          {/* <textarea type="text" value={inputValue} onChange={handleInputChange} className="increasing" /> */}
                          <textarea
                            type="text"
                            // {...register("prompt")}
                            placeholder={Data[0].data.cards[0].prompt}
                            className='increasing'
                          />
                          {/* {errors?.prompt && <span className='errorTextForm mt-1'> {errors.prompt.message} </span>} */}
                        </div>
                        <div className="mt-3">
                          {/* <Button type="submit" className="compute_btn" appearance="primary" loading={completionLoading}>Compute</Button> */}
                          <button type="submit" className="compute_btn" appearance="primary" disabled="true" >Compute</button>

                        </div>
                      </form>
                    </div>
                    <div className="Computation-time">
                      {/* <div className="title">Inference </div> */}
                      <div> {Data[0].data.cards[0].completions[1].completion} </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </>
  )
}

export default Newlanding